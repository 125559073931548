import { graphql } from '@server/gql';

export const createLabelForUserMutation = graphql(/* GraphQL */ `
    mutation createLabelForUser(
        $email: String!
        $label: String!
        $brandCode: String
    ) {
        user: createLabelForUser(
            email: $email
            label: $label
            brandCode: $brandCode
        ) {
            ...user
        }
    }
`);
