import type { Dictionary } from 'types';

import { cn } from '@/lib/utils';

import { Heading } from '../ui';

export default function VoyadoSignupFormSuccess({
    className,
    dictionary,
}: {
    className?: string;
    dictionary: Dictionary;
}) {
    return (
        <div
            className={cn(
                'flex flex-col gap-3 bg-white px-6 py-8 text-black',
                className,
            )}>
            <Heading
                size={{
                    initial: 5,
                    lg: 4,
                }}>
                {dictionary.voyadoSignupFormSuccessHeading ??
                    'Thank you for your application!'}
            </Heading>
            <p className="text-sm">
                {dictionary.voyadoSignUpFormSuccessText ??
                    'Thank you for your application. We will get back to you as soon as possible.'}
            </p>
        </div>
    );
}
