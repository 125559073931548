import { service } from '@server/client';
import type {
    CreateLabelForUserMutation,
    CreateLabelForUserMutationVariables,
} from '@server/gql/graphql';
import { createLabelForUserMutation } from '@server/queries/user';

export const createLabelForUser = async (
    variables: CreateLabelForUserMutationVariables,
) => {
    try {
        const response = await service<
            CreateLabelForUserMutation,
            CreateLabelForUserMutationVariables
        >({
            query: createLabelForUserMutation,
            variables,
        });
        return response;
    } catch (error: Error | unknown) {
        throw error;
    }
};
