import type { ReactNode } from 'react';

import { LoadingIcon } from '@/icons/LoadingIcon';

import { Button } from '../ui';

export default function VoyadoFormSubmitButton({
    children,
    isLoading,
}: {
    children?: ReactNode;
    isLoading?: boolean;
}) {
    return (
        <Button type="submit" variant="primary" size="lg">
            {isLoading ? (
                <LoadingIcon className="animate-spin" size={24} />
            ) : (
                children
            )}
        </Button>
    );
}
