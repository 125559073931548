import { service } from '@server/client';
import type {
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from '@server/gql/graphql';
import { updateUserMutation } from '@server/queries/user';

export const updateUser = async (variables: UpdateUserMutationVariables) => {
    try {
        const response = await service<
            UpdateUserMutation,
            UpdateUserMutationVariables
        >({
            query: updateUserMutation,
            variables,
        });
        return response;
    } catch (error: Error | unknown) {
        throw error;
    }
};
