'use client';

import { Button, Heading } from '@app/_components/ui';
import { Input, Select } from '@components/inputs';
import { useForm, ValidationError } from '@formspree/react';
import { useMemo, useState } from 'react';
import type { Dictionary } from 'types';
import xss from 'xss';

import { cn } from '@/lib/utils';

const TRADE_ACCOUNT_FORM_ID = 'mbldkyoj';

type TradeAccountFormProps = {
    className?: string;
    dictionary?: Dictionary;
    reference?: string;
};

export default function TradeAccountForm({
    className,
    reference,
    dictionary,
}: TradeAccountFormProps) {
    const [state, handleSubmit] = useForm(reference ?? TRADE_ACCOUNT_FORM_ID);
    const [email, setEmail] = useState<string | undefined>(undefined);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    const companyTypeOptions = useMemo(
        () => [
            {
                id: 1,
                value: dictionary?.tradeAccountCompanyTypeOption1,
                label: dictionary?.tradeAccountCompanyTypeOption1,
            },
            {
                id: 2,
                value: dictionary?.tradeAccountCompanyTypeOption2,
                label: dictionary?.tradeAccountCompanyTypeOption2,
            },
            {
                id: 3,
                value: dictionary?.tradeAccountCompanyTypeOption3,
                label: dictionary?.tradeAccountCompanyTypeOption3,
            },
            {
                id: 4,
                value: dictionary?.tradeAccountCompanyTypeOption4,
                label: dictionary?.tradeAccountCompanyTypeOption4,
            },
            {
                id: 5,
                value: dictionary?.tradeAccountCompanyTypeOption5,
                label: dictionary?.tradeAccountCompanyTypeOption5,
            },
            {
                id: 6,
                value: dictionary?.tradeAccountCompanyTypeOption6,
                label: dictionary?.tradeAccountCompanyTypeOption6,
            },
            {
                id: 7,
                value: dictionary?.tradeAccountCompanyTypeOption7,
                label: dictionary?.tradeAccountCompanyTypeOption7,
            },
            {
                id: 8,
                value: dictionary?.tradeAccountCompanyTypeOption8,
                label: dictionary?.tradeAccountCompanyTypeOption8,
            },
        ],
        [dictionary],
    );

    const tradeAccountFormSuccessText = useMemo(
        () =>
            dictionary?.tradeAccountFormSuccessText?.replace(
                '{email}',
                `<strong>${email}</strong>`,
            ),
        [email, dictionary?.tradeAccountFormSuccessText],
    );

    if (state.succeeded) {
        return (
            <div className={cn('flex flex-col gap-3', className)}>
                <Heading
                    size={{
                        initial: 5,
                        lg: 4,
                    }}>
                    {dictionary?.tradeAccountFormSuccessHeading}
                </Heading>
                {tradeAccountFormSuccessText && (
                    <p
                        className="text-sm"
                        dangerouslySetInnerHTML={{
                            __html: xss(tradeAccountFormSuccessText),
                        }}
                    />
                )}
            </div>
        );
    }

    return (
        <form
            onSubmit={handleSubmit}
            className={cn('flex flex-col gap-3', className)}>
            <Heading
                className="lg:mb-3"
                size={{
                    initial: 5,
                    lg: 4,
                }}>
                {dictionary?.tradeAccountFormHeading ?? 'Apply here'}
            </Heading>
            <div>
                <Input
                    type="text"
                    name="contactName"
                    id="contactName"
                    label={
                        dictionary?.tradeAccountContactNameLabel ??
                        'Contact name'
                    }
                    required
                    fullWidth
                />
                <ValidationError field="contactName" errors={state.errors} />
            </div>
            <div>
                <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    label={
                        dictionary?.tradeAccountCompanyNameLabel ??
                        'Company name'
                    }
                    required
                    fullWidth
                />
                <ValidationError field="companyName" errors={state.errors} />
            </div>
            <div>
                <Select
                    classNames={{
                        button: 'h-10 lg:h-10',
                    }}
                    name="companyType"
                    options={companyTypeOptions}
                    placeholder={
                        dictionary?.tradeAccountFormCompanyTypePlaceholder ??
                        'Company type'
                    }
                    required>
                    <ValidationError
                        field="companyType"
                        errors={state.errors}
                    />
                </Select>
            </div>
            <div>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    label={dictionary?.tradeAccountEmailLabel ?? 'Email'}
                    required
                    fullWidth
                    onChange={(event) => handleChange(event)}
                />
                <ValidationError field="email" errors={state.errors} />
            </div>
            <div>
                <Input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    label={
                        dictionary?.tradeAccountPhoneNumberLabel ??
                        'Phone number'
                    }
                    required
                    fullWidth
                />
                <ValidationError field="phoneNumber" errors={state.errors} />
            </div>
            <div>
                <Button
                    className="w-full"
                    variant="primary"
                    size="lg"
                    type="submit"
                    isLoading={state.submitting}>
                    {dictionary?.tradeAccountFormSubmitButton ??
                        'Submit application'}
                </Button>
            </div>
            <p className="mt-3 text-sm">
                {dictionary?.tradeAccountFormPrivacyText ??
                    'By submitting your application, you agree to our Privacy Policy and Terms & Conditions'}
            </p>
        </form>
    );
}
