import { service } from '@server/client';
import type {
    DeleteUserMutation,
    DeleteUserMutationVariables,
} from '@server/gql/graphql';
import { deleteUserMutation } from '@server/queries/user';

export const deleteUser = async ({ email }: { email: string }) => {
    try {
        const response = await service<
            DeleteUserMutation,
            DeleteUserMutationVariables
        >({
            query: deleteUserMutation,
            variables: {
                email,
            },
        });
        return response.deleteUser;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in deleteUser)'),
        );
        return e.response.data as DeleteUserMutation;
    }
};
