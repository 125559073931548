'use client';

import type { InputProps } from '@app/_components/ui';
import { Input } from '@app/_components/ui';
import type { ChangeEvent } from 'react';
import { type Path, useFormContext } from 'react-hook-form';

import { cn } from '@/lib/utils';

import type { VoyadoContact } from './VoyadoSignUpForm.schema';

type VoyadoFormInputFieldProps = InputProps & {
    name: Path<VoyadoContact>;
    label: string;
};

export default function VoyadoFormInputField({
    className,
    name,
    ...props
}: VoyadoFormInputFieldProps) {
    const {
        register,
        formState: { errors },
    } = useFormContext<VoyadoContact>();

    const error = errors[name];

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        register(name).onChange(event);
    };

    return (
        <>
            <Input
                className={cn('flex flex-col max-md:col-span-2', className)}
                error={error}
                renderError={(error) => (
                    <p className="mt-1 text-sm text-red-500">
                        {error?.message}
                    </p>
                )}
                {...register(name)}
                {...props}
                onChange={handleChange}
            />
        </>
    );
}
