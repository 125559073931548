import { registerUser } from '@server/requests/user';
import { createLabelForUser } from '@server/requests/user/createLabelForUser';
import { useMutation } from '@tanstack/react-query';

import { BRAND_CODE } from '@/config/common';
import { gTagEmailRegistration } from '@/utils/googleTags';
import { sendVoyadoEmailRegistrationEvent } from '@/utils/voyadoTracking';

export function useCreateVoyadoContact({ label }: { label?: string }) {
    return useMutation({
        mutationFn: registerUser,
        mutationKey: ['voyadoContact'],
        onSuccess: (data) => {
            console.log('Voyado contact created successfully:', data);

            sendVoyadoEmailRegistrationEvent(data.user.attributes.email);
            gTagEmailRegistration(data.user.attributes.email);
        },
        onError: (error) => {
            console.error('Error creating Voyado contact:', error);
        },
        onSettled: async (data, _, variables) => {
            if (!label) {
                console.warn(
                    'Label is not defined. Skipping createLabelForUser.',
                );
                return;
            }

            const email =
                data?.user?.attributes?.email ?? variables.contact.email;

            return createLabelForUser({
                brandCode: BRAND_CODE,
                email,
                label,
            })
                .then((response) => {
                    if (!response.user) {
                        console.error(
                            `Could not create label ${label} for user ${email}`,
                            response,
                        );
                        return;
                    }

                    console.log(
                        `Successfully created label ${label} for email ${email}`,
                    );
                })
                .catch((error) => {
                    console.error(
                        `Error creating label ${label} for user ${email}:`,
                        error,
                    );
                });
        },
    });
}
