import { graphql } from '@server/gql';

export const updateUserMutation = graphql(/* GraphQL */ `
    mutation updateUser(
        $contact: InputUser!
        $email: String!
        $brandCode: String
    ) {
        user: updateUser(
            contact: $contact
            email: $email
            brandCode: $brandCode
        ) {
            ...user
        }
    }
`);
