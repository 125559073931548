import { service } from '@server/client';
import type {
    GetTransactionQuery,
    GetTransactionQueryVariables,
} from '@server/gql/graphql';
import { getTransactionQuery } from '@server/queries/user';

export const getTransaction = async ({ email }: { email: string }) => {
    try {
        const response = await service<
            GetTransactionQuery,
            GetTransactionQueryVariables
        >({
            query: getTransactionQuery,
            variables: {
                email,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getTransaction)'),
        );
        return e.response.data as GetTransactionQuery;
    }
};
