'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import type { Dictionary } from 'types';

import { BRAND_CODE } from '@/config/common';
import { getMarketConfigFromLocale } from '@/config/market-configurations';
import { cn } from '@/lib/utils';

import { Heading } from '../ui';
import VoyadoCountrySelect from './VoyadoCountrySelect';
import VoyadoFormInputField from './VoyadoFormInputField';
import VoyadoFormSubmitButton from './VoyadoFormSubmitButton';
import { useCreateVoyadoContact } from './VoyadoSignUpForm.hooks';
import {
    type VoyadoContact,
    voyadoContactSchema,
} from './VoyadoSignUpForm.schema';
import VoyadoSignupFormSuccess from './VoyadoSignupFormSuccess';

export default function NewVoyadoSignUpForm({
    className,
    locale,
    reference,
    dictionary,
}: {
    className?: string;
    locale?: string;
    reference?: string | null;
    dictionary: Dictionary;
}) {
    const marketConfig = getMarketConfigFromLocale(locale);
    const methods = useForm<VoyadoContact>({
        resolver: zodResolver(voyadoContactSchema),
    });
    const {
        mutate: createVoyadoContactMutation,
        isSuccess,
        isPending,
    } = useCreateVoyadoContact({
        label: reference ?? 'voyado-sign-up-form',
    });

    const onSubmit = methods.handleSubmit((data) => {
        createVoyadoContactMutation({
            brandCode: BRAND_CODE,
            contact: {
                ...data,
                countryCode: data.countryCode ?? marketConfig.code,
                externalStoreId: marketConfig.externalStoreId,
                source: reference ?? 'voyado-sign-up-form',
            },
        });
    });

    if (isSuccess) {
        return <VoyadoSignupFormSuccess dictionary={dictionary} />;
    }

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={onSubmit}
                className={cn(
                    'flex flex-col gap-2 bg-white p-4 text-black',
                    className,
                )}>
                {dictionary?.voyadoSignUpFormHeading && (
                    <Heading
                        className="lg:mb-3"
                        size={{
                            initial: 5,
                            lg: 4,
                        }}>
                        {dictionary?.voyadoSignUpFormHeading}
                    </Heading>
                )}
                <VoyadoFormInputField
                    type="text"
                    name="firstName"
                    label={
                        dictionary?.voyadoSignUpFormFirstNameLabel ??
                        'First name'
                    }
                    placeholder="First name"
                />
                <VoyadoFormInputField
                    type="text"
                    name="lastName"
                    label={
                        dictionary?.voyadoSignUpFormLastNameLabel ?? 'Last name'
                    }
                    placeholder="Last name"
                />
                <VoyadoFormInputField
                    type="email"
                    name="email"
                    label={
                        dictionary?.voyadoSignUpFormEmailLabel ??
                        'Email address'
                    }
                    placeholder="Email address"
                />
                <VoyadoFormInputField
                    type="text"
                    name="company"
                    label="Company name"
                    placeholder="Company name"
                />
                <VoyadoFormInputField
                    type="text"
                    name="companyTitle"
                    label="Your role / title"
                    placeholder="Your role / title"
                />
                <VoyadoCountrySelect
                    marketConfig={marketConfig}
                    label={dictionary?.country}
                    name="countryCode"
                />
                <VoyadoFormSubmitButton isLoading={isPending}>
                    {dictionary?.voyadoSignUpFormSubmitButton ?? 'Submit'}
                </VoyadoFormSubmitButton>
            </form>
        </FormProvider>
    );
}
