import { service } from '@server/client';
import type {
    SignupUserMutation,
    SignupUserMutationVariables,
} from '@server/gql/graphql';
import { signupUserMutation } from '@server/queries/user';

export const signupUser = async ({
    email,
    countryCode,
}: {
    email: string;
    countryCode: string;
}) => {
    try {
        const response = await service<
            SignupUserMutation,
            SignupUserMutationVariables
        >({
            query: signupUserMutation,
            variables: {
                email,
                countryCode,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in signupUser)'),
        );
        return e.response.data as SignupUserMutation;
    }
};
