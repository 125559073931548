import { service } from '@server/client';
import type {
    RegisterUserMutation,
    RegisterUserMutationVariables,
} from '@server/gql/graphql';
import { registerUserMutation } from '@server/queries/user';

export const registerUser = async (
    variables: RegisterUserMutationVariables,
) => {
    try {
        const response = await service<
            RegisterUserMutation,
            RegisterUserMutationVariables
        >({
            query: registerUserMutation,
            variables,
        });

        return response;
    } catch (error: Error | unknown) {
        console.error('registerUser error', error);
        throw error;
    }
};
