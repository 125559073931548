import { service } from '@server/client';
import type { GetUserQuery, GetUserQueryVariables } from '@server/gql/graphql';
import { getUserQuery } from '@server/queries/user';

export const getUser = async ({ email }: { email: string }) => {
    try {
        const response = await service<GetUserQuery, GetUserQueryVariables>({
            query: getUserQuery,
            variables: {
                email,
            },
        });
        return response;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getUser)'),
        );
        return e.response.data as GetUserQuery;
    }
};
